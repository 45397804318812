@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #bcbfd1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0040f1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0020f1;
}
